import React, { createContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from './authContext';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
    const [order, setOrder] = useState([]);
    const [history, setHistory] = useState([]);
    const [status, setStatus] = useState('waiting');
    const [forceUpdate, setForceUpdate] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const { token, logout, userID } = useAuth();

    let ID = null;

    if (token) {
        ID = jwtDecode(token).id;
    }

    function generateOrderID() {
        const timestamp = new Date().getTime();
        const randomNum = Math.floor(Math.random() * 1000);
        return `ORDER-${timestamp}-${randomNum}`;
    }

    const fetchOrderData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/orderSpecialList/${ID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseHistory = await fetch(`${process.env.REACT_APP_API}/orderSpecialListHistory/${ID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 401) {
                logout();
            }

            if (!response.ok) {
                toast.error('An error occurred while fetching order data.', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                return;
            }


            if (!responseHistory.ok) {
                toast.error('An error occurred while fetching order history data.', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                return;
            }

            const orderData = await response.json();
            const orderHistoryData = await responseHistory.json();

            console.log(orderData);
            

            if (orderData && Array.isArray(orderData) && orderData.length > 0) {
                // Map through the orderData array to create an array of orders
                const orders = orderData.map(order => {
                    const items = JSON.parse(order.items);
                    return {
                        id: order.id,
                        name: order.id,
                        status: order.status,
                        items: items,
                    };
                });

                // Update state with the array of orders
                setOrder(orders);
                setStatus(orders[0].status);  // You may want to track the status of each order separately
            } else {
                setOrder([]);
            }


            if (orderHistoryData && Array.isArray(orderHistoryData) && orderHistoryData.length > 0) {
                // Map through the orderData array to create an array of orders
                const orders = orderHistoryData.map(order => {
                    const items = JSON.parse(order.items);
                    return {
                        id: order.id,
                        name: order.id,
                        status: order.status,
                        items: items,
                    };
                });

                // Update state with the array of orders
                setHistory(orders);
                setStatus(orders[0].status);  // You may want to track the status of each order separately
            } else {
                setHistory([]);
            }
        } catch (error) {
            // toast.error('An error occurred while fetching order data.', {
            //     position: 'top-right',
            //     autoClose: 3000,
            // });
        }
    };

    useEffect(() => {
        if (token) {
            fetchOrderData(); // Initial fetch

            const interval = setInterval(() => {
                fetchOrderData();
            }, 5000); // Fetch every 5 seconds

            return () => clearInterval(interval); // Cleanup on unmount
        } else {
            setOrder([]);
            setHistory([]);
        }
    }, [forceUpdate, token]);

    const createOrder = async (cart, couponData, additionalDiscount) => {
        try {
            // Create an array to hold the updated cart items with their order IDs
            const updatedCart = [];
            const order_id = generateOrderID();

            // Update cart items with coupon data and additional discount if they exist
            const cartWithCoupon = cart.map(item => {
                let coupon_id = 0;
                let additional_discount = 0;

                // Check if there is a coupon for this item
                if (couponData.length > 0) {
                    // First, try to find a coupon with a matching p_id
                    let coupon = couponData.find(coupon => coupon.p_id === item.id);

                    // If no matching coupon by p_id is found, look for a coupon with p_id === ''
                    if (!coupon) {
                        coupon = couponData.find(coupon => coupon.p_id === '');
                    }

                    // If a coupon is found, assign its id
                    if (coupon) {
                        coupon_id = coupon.id; // Assign coupon_id
                    }
                }

                // Check if there is an additional discount for this item
                if (additionalDiscount.length > 0) {
                    const additional = additionalDiscount.find(discount => discount.id === item.id);
                    if (additional) {
                        additional_discount = additional.discount; // Assign additional discount if found
                    }
                }

                // Return the updated item with coupon and additional discount                
                return {
                    ...item,
                    coupon_id, // Add coupon_id if applicable
                    additional_discount // Add additional_discount to the item
                };
            });

            const data = {
                order_id: order_id,
                cart: cartWithCoupon
            };

            const response = await fetch(`${process.env.REACT_APP_API}/order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                toast.error('Something went wrong while creating order.', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                return;
            } else {
                const { orderIds } = await response.json();

                for (const [index, item] of cartWithCoupon.entries()) {
                    try {
                        item.price = item.current_price;
                        updatedCart.push({
                            ...item,
                            order_id: orderIds[index], // Assign each orderId based on index
                            status: 'Pending',
                        });
                    } catch (error) {
                        toast.error('An error occurred while creating orders.', {
                            position: 'top-right',
                            autoClose: 3000,
                        })
                    }
                }

                // Add the new order to the existing orders array
                setOrder((prevOrders) => [
                    ...prevOrders,
                    {
                        id: updatedCart[0].order_id, // Set the order ID from the first item
                        name: order_id, // Optionally, use this for display purposes
                        status: 'Pending',
                        items: updatedCart,
                    },
                ]);
                setStatus('Pending');
            }
        } catch (error) {
            toast.error('An error occurred while creating orders.', {
                position: 'top-right',
                autoClose: 3000,
            });
        }
    };

    const cancelOrderItem = async (itemToCancel) => {
        try {
            const data = { status: 'Canceled' };

            // Function to handle the API request
            const cancelItem = async (orderId) => {
                const response = await fetch(`${process.env.REACT_APP_API}/order/${orderId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(data),
                });
                if (!response.ok) {
                    throw new Error(`Failed to cancel order with ID ${orderId}`);
                }
            };

            if (itemToCancel.items) {
                // If there are multiple items, use Promise.all to handle multiple requests
                const cancelPromises = itemToCancel.items.map(item => cancelItem(item.order_id));
                await Promise.all(cancelPromises);
            } else {
                // Single item case
                await cancelItem(itemToCancel.order_id);
            }

            // Update the state to reflect the canceled item
            setForceUpdate(!forceUpdate);
            setStatus('Pending'); // Optionally update the status if needed

        } catch (error) {
            toast.error('An error occurred while updating order data.', {
                position: 'top-right',
                autoClose: 3000,
            });
        }
    };

    return (
        <OrderContext.Provider value={{ order, history, status, createOrder, cancelOrderItem, setForceUpdate, forceUpdate }}>
            {children}
        </OrderContext.Provider>
    );
};

export const useOrder = () => React.useContext(OrderContext);
