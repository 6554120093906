import React, { createContext, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { jwtDecode } from 'jwt-decode'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate()
    const [token, setTokenState] = useState(localStorage.getItem('token') || null)
    const [userID, setUserID] = useState(null)
    const [shippingAddress, setShippingAddress] = useState(null)
    const [phonenumber, setPhoneNumber] = useState(null)
    const [firstname, setFirstName] = useState(null)
    const [lastname, setLastName] = useState(null)
    const [showModal, setShowModal] = useState(false);

    if (token !== null) {
        const decodedToken = jwtDecode(token);
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/user/${decodedToken.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();

                setPhoneNumber(result.phonenumber);
                setFirstName(result.firstname);
                setLastName(result.lastname);
                setShippingAddress(result.address);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData()
    }

    const setToken = (newToken) => {
        localStorage.setItem('token', newToken)
        setTokenState(newToken)
        setUserID(jwtDecode(newToken).id)
    }

    const login = (userData, tokenData) => {
        // setUser(userData)
        setToken(tokenData)

        navigate('/')
    }

    const logout = () => {
        // localStorage.removeItem('user')
        localStorage.removeItem('token')
        setTokenState(null)
    }

    return (
        <AuthContext.Provider value={{ token, setToken, login, logout, phonenumber, setPhoneNumber, userID, setUserID, shippingAddress, setShippingAddress, firstname, setFirstName, lastname, setLastName, showModal, setShowModal }}>
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export const useAuth = () => useContext(AuthContext)