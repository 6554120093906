import React, { useState, useEffect } from 'react';
import './shop.css';
import { AiFillHeart, AiFillEye, AiOutlineClose } from 'react-icons/ai';
import { useCart } from './contexts/cartContext'; // Import the custom hook
import { useProducts } from './contexts/productContext';
import CategoriesComponent from './categories';
import { useCategories } from './contexts/categoryContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { toast } from 'react-toastify';
import { useAuth } from "./contexts/authContext";
import { useWishlist } from './contexts/wishlistContext';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import PaginationNav from './common/Pagination';

const Shop = () => {
    // Toggle Product Detail
    const { categories, loadingCategory } = useCategories();
    const [showDetail, setShowDetail] = useState(false);
    const [detail, setDetail] = useState([]);
    const { addToCart, removeFromCart, getQuantity, decreaseQty, decreaseQtyInput, increaseQty, increaseQtyInput, additionalDiscount, setAdditionalDiscount } = useCart(); // Use Cart Context
    const { filterProductsByCategory, filteredProducts, advertisements, loading, error, searchProducts, isSearching } = useProducts();
    const [activeCategory, setActiveCategory] = useState(null);
    const [currentProducts, setCurrentProducts] = useState(filteredProducts);
    const hasFilterProductsByCategory = currentProducts && currentProducts.length > 0;
    const { userID, token, showModal, setShowModal, logout } = useAuth()
    const { forceUpdate, setForceUpdate } = useWishlist()
    const { t, i18n } = useTranslation();
    const [productPerPage, setProductsPerPage] = useState(18);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = productPerPage; // Adjust this to your desired number of products per page
    // Calculate the indices for the current page's products
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProductsPage = currentProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    function calculatePercentageChange(originalPrice, currentPrice) {
        // Check if the original price is zero to avoid division by zero
        if (originalPrice === 0) {
            throw new Error("Original price cannot be zero.");
        }

        // Calculate the percentage change
        const percentageChange = ((currentPrice - originalPrice) / originalPrice) * 100;

        return (percentageChange).toFixed(2);
    }

    const totalPriceAfterDiscount = (total, discount) => {
        const totalPrice = total - (total * (discount / 100));
        return totalPrice;
    }

    const calculateDiscountedPrice = (
        quantity,
        basePrice,
        minOrderQuantity,
        minDiscount,
        maxDiscount,
        minItemDiscount,
        maxItemDiscount
    ) => {
        let appliedDiscount = 0; // Default discount is 0

        // Convert minItemDiscount and maxItemDiscount to arrays of numbers
        const minItemDiscountArray = minItemDiscount.split(',').map(Number);
        const maxItemDiscountArray = maxItemDiscount.split(',').map(Number);

        // If the quantity is equal to minOrderQuantity, set appliedDiscount to 0
        if (quantity === minOrderQuantity) {
            appliedDiscount = 0;
        }
        // Apply minDiscount if the quantity is within the minItemDiscount range
        else if (quantity >= minItemDiscountArray[0] && quantity <= minItemDiscountArray[1]) {
            appliedDiscount = minDiscount;
        }
        // Apply maxDiscount if the quantity is within the maxItemDiscount range
        else if (quantity >= maxItemDiscountArray[0] && quantity <= maxItemDiscountArray[1]) {
            appliedDiscount = maxDiscount;
        }
        // If the quantity is between the two ranges, calculate a proportional discount
        else if (minItemDiscountArray[0] === 0 && minItemDiscountArray[1] === 0 && quantity > minOrderQuantity && quantity < maxItemDiscountArray[0]) {
            const discountRange = maxDiscount - minDiscount;
            const qtyRange = maxItemDiscountArray[0] - minOrderQuantity;
            const qtyBeyondMin = quantity - minOrderQuantity;

            // Calculate the proportional discount
            appliedDiscount = minDiscount + (discountRange * (qtyBeyondMin / qtyRange));
        }
        // If the quantity is between the two ranges, calculate a proportional discount
        else if (quantity > minItemDiscountArray[1] && quantity < maxItemDiscountArray[0]) {
            const discountRange = maxDiscount - minDiscount;
            const qtyRange = maxItemDiscountArray[0] - minItemDiscountArray[1];
            const qtyBeyondMin = quantity - minItemDiscountArray[1];

            // Calculate the proportional discount
            appliedDiscount = minDiscount + (discountRange * (qtyBeyondMin / qtyRange));
        }
        // If quantity exceeds maxItemDiscount range, apply maxDiscount
        else if (quantity > maxItemDiscountArray[1]) {
            appliedDiscount = maxDiscount;
        }

        // Calculate the discounted price per unit
        const discountedPrice = basePrice - (basePrice * (appliedDiscount / 100));

        // Calculate the total price
        const totalPrice = quantity * discountedPrice;

        // If no match is found, return totalPrice = quantity * basePrice;
        if (appliedDiscount === 0) {
            return { totalPrice: quantity * basePrice, appliedDiscount: 0 };
        }

        console.log(appliedDiscount);

        // Return both the total price and the applied discount percentage
        return { totalPrice, appliedDiscount };
    };

    // Update currentCategories when categories prop changes
    useEffect(() => {
        setCurrentProducts(filteredProducts);
    }, [filteredProducts, productPerPage]);

    const handleCategorySelect = async (categoryData) => {
        try {
            var url = ``;

            if (categoryData.c_id) {
                url = `${process.env.REACT_APP_API}/product_no_auth_subcategory/${categoryData.id}` //fetch products by categories
            } else {
                url = `${process.env.REACT_APP_API}/product_no_auth_category/${categoryData.id}` //fetch products by sub-categories
            }
            const response = await fetch(url, {
                // Fetch products from remote server
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                toast.error('Something went wrong while fetching products by sub-categories data.', {
                    position: 'top-right',
                    autoClose: 3000, // 3 seconds
                });
            }

            const data = await response.json();

            setCurrentProducts(data);

        } catch (e) {
            toast.error('Something went wrong.', {
                position: 'top-right',
                autoClose: 3000, // 3 seconds
            });
        }
    };

    // Showing Detail Box
    const detailpage = (product) => {
        const detaildata = [{ product }];
        const productdetail = detaildata[0]['product'];
        setDetail(productdetail);
        setShowDetail(true);
    };

    const closedetail = () => {
        setShowDetail(false);
    };

    const handleAddToCart = (product) => {
        const current_pro_quantity = getQuantity(product);

        // Calculate the discount based on the updated quantity
        const newQuantity = current_pro_quantity + 1;
        const returnedData = calculateDiscountedPrice(newQuantity, product.current_price, product.min_order, product.min_discount, product.max_discount, product.item_min_discount, product.item_max_discount);

        if (current_pro_quantity === 0) {
            // First time adding to cart
            addToCart(product);
        } else {
            // Increasing quantity
            increaseQty(product);

            // Update additionalDiscount array, checking if the product already exists
            if (returnedData.appliedDiscount > 0) {
                const updatedAdditionalDiscount = additionalDiscount.some(discount => discount.id === product.id)
                    ? additionalDiscount.map(discount =>
                        discount.id === product.id
                            ? { ...discount, discount: returnedData.appliedDiscount }
                            : discount
                    )
                    : [...additionalDiscount, { id: product.id, discount: returnedData.appliedDiscount }];

                setAdditionalDiscount(updatedAdditionalDiscount);
            }
        }
    };

    const handleRemoveFromCart = (product) => {
        const current_pro_quantity = getQuantity(product);

        if (current_pro_quantity <= product.min_order) {
            // Remove product if it's at or below min_order
            removeFromCart(product);

            // Filter out the discount with the matching id
            const updatedAdditionalDiscount = additionalDiscount.filter(discount => discount.id !== product.id);

            // Update the state with the filtered discounts
            setAdditionalDiscount(updatedAdditionalDiscount);
        } else {
            // Decrease the quantity by 1
            decreaseQty(product);

            // Calculate the new discount based on the decreased quantity
            const returnedData = calculateDiscountedPrice(
                current_pro_quantity - 1,
                product.current_price,
                product.min_order,
                product.min_discount,
                product.max_discount,
                product.item_min_discount,
                product.item_max_discount
            );

            // If the discount is greater than 0, update the list; if not, remove the product from the discount list
            const updatedAdditionalDiscount = returnedData.appliedDiscount > 0
                ? additionalDiscount.some(discount => discount.id === product.id)
                    ? additionalDiscount.map(discount =>
                        discount.id === product.id
                            ? { ...discount, discount: returnedData.appliedDiscount } // Update discount if product exists
                            : discount
                    )
                    : [...additionalDiscount, { id: product.id, discount: returnedData.appliedDiscount }] // Add new discount
                : additionalDiscount.filter(discount => discount.id !== product.id); // Remove if discount is 0

            setAdditionalDiscount(updatedAdditionalDiscount);
        }
    };

    const handleWishlist = async (product) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/wishlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    product_id: product.id,
                    customer_id: userID ? userID : jwtDecode(token).id
                }),
            });

            if (!response.ok) {
                toast.error('Something went wrong while adding product to wishlist.', {
                    position: 'top-right',
                    autoClose: 3000, // 3 seconds
                })
            } else {
                setForceUpdate(!forceUpdate);
                toast.success('Product added to wishlist.', {
                    position: 'top-right',
                    autoClose: 3000, // 3 seconds
                })
            }

        } catch (error) {
            toast.error('Something went wrong.', {
                position: 'top-right',
                autoClose: 3000, // 3 seconds
            })
        }
    }

    const handleAddToCartInput = (product, newQty) => {
        // const current_pro_quantity = getQuantity(product);

        const returnedData = calculateDiscountedPrice(newQty, product.current_price, product.min_order, product.min_discount, product.max_discount, product.item_min_discount, product.item_max_discount);

        // Increasing quantity
        increaseQtyInput(product, newQty);

        // Update additionalDiscount array, checking if the product already exists
        if (returnedData.appliedDiscount > 0) {
            const updatedAdditionalDiscount = additionalDiscount.some(discount => discount.id === product.id)
                ? additionalDiscount.map(discount =>
                    discount.id === product.id
                        ? { ...discount, discount: returnedData.appliedDiscount }
                        : discount
                )
                : [...additionalDiscount, { id: product.id, discount: returnedData.appliedDiscount }];

            setAdditionalDiscount(updatedAdditionalDiscount);
        }
    };

    const handleRemoveFromCartInput = (product) => {
        // Decrease the quantity by 1
        decreaseQtyInput(product, product.min_order);

        // Calculate the new discount based on the decreased quantity
        const returnedData = calculateDiscountedPrice(
            product.min_order,
            product.current_price,
            product.min_order,
            product.min_discount,
            product.max_discount,
            product.item_min_discount,
            product.item_max_discount
        );

        // If the discount is greater than 0, update the list; if not, remove the product from the discount list
        const updatedAdditionalDiscount = returnedData.appliedDiscount > 0
            ? additionalDiscount.some(discount => discount.id === product.id)
                ? additionalDiscount.map(discount =>
                    discount.id === product.id
                        ? { ...discount, discount: returnedData.appliedDiscount } // Update discount if product exists
                        : discount
                )
                : [...additionalDiscount, { id: product.id, discount: returnedData.appliedDiscount }] // Add new discount
            : additionalDiscount.filter(discount => discount.id !== product.id); // Remove if discount is 0

        setAdditionalDiscount(updatedAdditionalDiscount);

    };

    const handleQuantityChange = (e, curElm) => {
        const newQty = e.target.value.trim() === '' ? '' : parseInt(e.target.value);
        // Update the cart item's quantity if the input is not empty and is a number
        if (newQty === '' || (!isNaN(newQty) && newQty >= 0)) {
            // If input is not empty and greater than the minimum order, handle the cart update
            if (newQty !== '' && newQty > curElm.min_order) {
                handleAddToCartInput(curElm, newQty);
            } else {
                handleRemoveFromCartInput(curElm);
            }
        }
    };

    return (
        <>
            {showDetail ? (
                <div className="product_detail dark:bg-drkmode">
                    <button className="close_btn dark:text-drkmodebrand" onClick={closedetail}>
                        <AiOutlineClose />
                    </button>
                    <div className="container">
                        <div className="img_box" style={{ position: 'relative', maxWidth: '500px', margin: '0 auto' }}>
                            {detail.price > detail.current_price && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        zIndex: 10,
                                    }}
                                >
                                    {calculatePercentageChange(detail.price, detail.current_price)}% {t('off')}
                                </div>
                            )}
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                modules={[Pagination, Navigation, Autoplay]}
                                autoplay={{ delay: 3000 }}
                                loop={true}
                            >
                                {detail.photos && detail.photos.length > 0
                                    ? detail.photos.map((photo, index) => (
                                        <SwiperSlide key={index} className='flex justify-center'>
                                            <img
                                                src={`${process.env.REACT_APP_API}/${photo}`}
                                                alt={`Slide ${index + 1}`}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    maxHeight: '400px',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </SwiperSlide>
                                    ))
                                    : null}
                            </Swiper>
                        </div>
                        <div className="info overflow-auto max-h-[400px]">
                            <h4 className="dark:text-gray-200">
                                / {detail.category_name} / {detail.subcategory_name}
                            </h4>
                            <h2 className="dark:text-gray-200">{detail.name}</h2>
                            <p className="dark:text-gray-200">{detail.description}</p>

                            {/* Extract the additional discount logic outside of JSX */}
                            {(() => {
                                const additional = additionalDiscount.find(discount => discount.id === detail.id);

                                // Display discounted price if necessary
                                if (detail.price === detail.current_price) {
                                    return additional ? (
                                        <>
                                            <h3 className="font-bold" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                {t('etb')} {detail.current_price}
                                            </h3>
                                            <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                                {t('etb')} {totalPriceAfterDiscount(detail.current_price, additional.discount).toFixed(2)}
                                            </h3>
                                        </>
                                    ) : (
                                        <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                            {t('etb')} {detail.current_price}
                                        </h3>
                                    );
                                } else if (detail.price > detail.current_price) {
                                    return additional ? (
                                        <>
                                            <h3 className="font-bold" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                {t('etb')} {detail.price}
                                            </h3>
                                            <h3 className="font-bold" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                {t('etb')} {detail.current_price}
                                            </h3>
                                            <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                                {t('etb')} {totalPriceAfterDiscount(detail.current_price, additional.discount).toFixed(2)}
                                            </h3>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="font-bold" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                {t('etb')} {detail.price}
                                            </h3>
                                            <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                                {t('etb')} {detail.current_price}
                                            </h3>
                                        </>
                                    );
                                } else {
                                    return (
                                        <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                            {t('etb')} {detail.current_price}
                                        </h3>
                                    );
                                }
                            })()}

                            <p className="dark:text-gray-200">{t('minimum_order')}: {detail.min_order}</p>
                            <p className="dark:text-gray-200">{t('available')}: {detail.stock}</p>
                            <p className="dark:text-gray-200">{t('discount_per_item')}: {detail.min_discount}% - {detail.max_discount}%</p>

                            {getQuantity(detail) > 0 ? (
                                <div className="quantity_controls">
                                    <button
                                        className="bg-brand text-white dark:bg-drkmodebrand"
                                        onClick={() => handleRemoveFromCart(detail)}
                                    >
                                        -
                                    </button>
                                    <input type='number' className='!text-center dark:bg-drkmode dark:text-gray-200 w-20'
                                        min={detail.min_order}
                                        value={getQuantity(detail)}
                                        onChange={(e) => {
                                            handleQuantityChange(e, detail)
                                        }} />
                                    {/* <span className="dark:text-gray-200">{getQuantity(detail)}</span> */}
                                    <button
                                        className="bg-brand text-white dark:bg-drkmodebrand"
                                        onClick={() => handleAddToCart(detail)}
                                    >
                                        +
                                    </button>
                                </div>
                            ) : (
                                <button
                                    className="bg-brand text-white dark:bg-drkmodebrand"
                                    onClick={() => handleAddToCart(detail)}
                                >
                                    {t('add_to_cart')}
                                </button>
                            )}
                        </div>

                    </div >
                </div >
            ) : null}

            <div className='shop dark:bg-drkmode'>
                <div className='container'>
                    {
                        !isSearching &&
                        (
                            <>
                                <div className='banner_top'>
                                    <Swiper
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        pagination={{ clickable: true }}
                                        modules={[Pagination, Navigation, Autoplay]}
                                        autoplay={{ delay: 3000 }}
                                        loop={true}
                                    >
                                        {
                                            advertisements && advertisements.length > 0
                                                ?
                                                (
                                                    advertisements.map((advertisement, index) => {
                                                        return (
                                                            <SwiperSlide key={advertisement.id}>
                                                                <img src={`${process.env.REACT_APP_API}/${advertisement.photo}`} alt="Slide 1" className="w-full h-auto" />
                                                            </SwiperSlide>
                                                        )
                                                    })
                                                )
                                                :
                                                (
                                                    <SwiperSlide>
                                                        <img src="image/Multi-Banner-3.webp" alt="Slide 1" className="w-full h-auto" />
                                                    </SwiperSlide>
                                                )
                                        }
                                    </Swiper>
                                </div>
                                <div className='left_box'>
                                    <div className='category'>
                                        <div className='box'>
                                            <CategoriesComponent
                                                categories={categories}
                                                loadingCategories={loadingCategory}
                                                onCategorySelect={handleCategorySelect}
                                                activeCategory={activeCategory}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    <div className='right_box'>
                        <div className='product_box'>
                            <h2 className='text-center dark:text-gray-200'>{t('all_product_list')}</h2>

                            {
                                loading
                                    ?
                                    (
                                        <p className='text-center dark:text-gray-200'>{t('loading_products')} ....</p>
                                    )
                                    :
                                    (
                                        <div className={`${hasFilterProductsByCategory ? 'product_container' : 'has-filter'}`}>
                                            <div className={`grid ${hasFilterProductsByCategory ? 'grid-cols-2' : 'grid-cols-1'} ${hasFilterProductsByCategory ? 'md:grid-cols-3' : 'md:grid-cols-1'}`}>
                                                {

                                                    currentProductsPage.length > 0
                                                        ? (
                                                            currentProductsPage.map((curElm) => (
                                                                <div className="box" key={curElm.id}>
                                                                    <div className="img_box">
                                                                        {curElm.price > curElm.current_price && (
                                                                            <div style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' }}>
                                                                                {calculatePercentageChange(curElm.price, curElm.current_price)}% {t('off')}
                                                                            </div>
                                                                        )}
                                                                        <img src={`${process.env.REACT_APP_API}/${curElm.photos[0]}`} alt='' />
                                                                        <div className="icon">
                                                                            <li onClick={() => detailpage(curElm)} className='dark:hover:bg-drkmodebrand hover:bg-brand bg-white dark:bg-white'>
                                                                                <AiFillEye className='dark:hover:text-gray-200 hover:text-gray-200' />
                                                                            </li>

                                                                            {
                                                                                token &&
                                                                                <li onClick={() => handleWishlist(curElm)} className='dark:hover:bg-drkmodebrand hover:bg-brand bg-white dark:bg-white'>
                                                                                    <AiFillHeart className='dark:hover:text-gray-200 hover:text-gray-200' />
                                                                                </li>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="detail">
                                                                        <h3 className='dark:text-gray-200'>{curElm.name}</h3>
                                                                        {(() => {
                                                                            const additional = additionalDiscount.find(discount => discount.id === curElm.id);

                                                                            // Display discounted price if necessary
                                                                            if (curElm.price === curElm.current_price) {
                                                                                return additional ? (
                                                                                    <>
                                                                                        <h3 className="font-bold" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                                                            {t('etb')} {curElm.current_price}
                                                                                        </h3>
                                                                                        <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                                                                            {t('etb')} {totalPriceAfterDiscount(curElm.current_price, additional.discount).toFixed(2)}
                                                                                        </h3>
                                                                                    </>
                                                                                ) : (
                                                                                    <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                                                                        {t('etb')} {curElm.current_price}
                                                                                    </h3>
                                                                                );
                                                                            } else if (curElm.price > curElm.current_price) {
                                                                                return additional ? (
                                                                                    <>
                                                                                        <h3 className="font-bold" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                                                            {t('etb')} {curElm.price}
                                                                                        </h3>
                                                                                        <h3 className="font-bold" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                                                            {t('etb')} {curElm.current_price}
                                                                                        </h3>
                                                                                        <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                                                                            {t('etb')} {totalPriceAfterDiscount(curElm.current_price, additional.discount).toFixed(2)}
                                                                                        </h3>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <h3 className="font-bold" style={{ textDecoration: 'line-through', color: 'red' }}>
                                                                                            {t('etb')} {curElm.price}
                                                                                        </h3>
                                                                                        <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                                                                            {t('etb')} {curElm.current_price}
                                                                                        </h3>
                                                                                    </>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                                                                        {t('etb')} {curElm.current_price}
                                                                                    </h3>
                                                                                );
                                                                            }
                                                                        })()}

                                                                        <p className='stock'>{t('available')}: {curElm.stock}</p>
                                                                        {getQuantity(curElm) > 0 ? (
                                                                            <div className="quantity_controls">
                                                                                <button className='bg-brand dark:bg-drkmodebrand' onClick={() => handleRemoveFromCart(curElm)}>-</button>
                                                                                <input type='number' id="shop_front_number_input" className='!text-center dark:bg-drkmode dark:text-gray-200 w-20'
                                                                                    min={curElm.min_order}
                                                                                    value={getQuantity(curElm)}
                                                                                    onChange={(e) => {
                                                                                        handleQuantityChange(e, curElm)
                                                                                    }} />
                                                                                <button className='bg-brand dark:bg-drkmodebrand' onClick={() => handleAddToCart(curElm)}>+</button>
                                                                            </div>
                                                                        ) : (
                                                                            <button className='bg-brand dark:bg-drkmodebrand' onClick={() => handleAddToCart(curElm)}>{t('add_to_cart')}</button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )
                                                        : (
                                                            <p className="text-center">{t('no_products')}</p>
                                                        )

                                                }
                                            </div>
                                        </div>
                                    )
                            }


                            <div className="flex justify-center mt-10 space-x-4">
                                <label htmlFor="" className='dark:text-white text-brand'>{t('item_per_page')}</label>
                                <select
                                    className='w-24 dark:bg-drkmodebrand dark:text-white bg-brand text-white p-1'
                                    style={{ borderRadius: '5px' }}
                                    value={productsPerPage}
                                    onChange={(e) => setProductsPerPage(e.target.value)}
                                >
                                    <option value="6">6</option>
                                    <option value="12">12</option>
                                    <option value="18">18</option>
                                    <option value="24">24</option>
                                    <option value="30">30</option>
                                    <option value="36">36</option>
                                    <option value="42">42</option>
                                    <option value="48">48</option>
                                    <option value="54">54</option>
                                </select>
                            </div>
                            {/* Pagination */}
                            <PaginationNav
                                productsPerPage={productsPerPage}
                                totalProducts={currentProducts.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Shop;
